<template>
  <el-dialog
    title="Run Meal Recommendations"
    :visible.sync="shouldShow"
    append-to-body
    width="30%"
  >
    <el-row type="flex">
      <el-col :span="24">
        <p>
          Are you sure you want to run meal recommendations for this customer?
        </p>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" :loading="loading" @click="runRecommendations"
        >Run recommendations</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import customer from "@/requests/customers/customer/actions";

export default {
  name: "CustomerMealRecommendations",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    runRecommendations() {
      this.loading = true;

      const payload = {
        customer_email: this.customer.email.toLowerCase(),
      };
      customer
        .recommendations(payload)
        .then((response) => {
          if (response.data.status) {
            this.loading = false;
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeEvent();
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
